@import "../styles/variables";

.item {
  display: flex;
  margin: 0 0 $baseMargin * 2 0;

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .showcase {
    width: 50%;
    margin: 0 calc($baseMargin / 2) 0 0;
  }

  .info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;

    .title {
      font-size: 2.5em;
      font-weight: 900;
      color: $pink;
    }

    .client {
      display: none;
      font-size: 1.8em;
      font-weight: 100;
      letter-spacing: -0.01em;
      // line-height: 0.2em;
    }

    .description {
      font-size: 16px;
      line-height: 18px;
      margin: 1em 0 0 0;
      white-space: pre-wrap;
    }

    .technologies {
      position: relative;
      width: auto;
      padding: 0 0.5em 0 0.5em;
      margin: 0 0 0.45em 0; // auto 0 0 0;
      color: white;
      font-weight: 500;

      .divider {
        color: $pink;
        margin: 0 0.15em;
      }

      .background {
        background-color: $grey;
      }
    }
  }
}

@media screen and (min-width: $max-width) {
  .item {
    .info {
      .title {
        font-size: 40px;
      }

      .client {
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width: $tablet-max) {
  .item {
    flex-direction: column;

    .showcase {
      width: 100%;
    }

    .info {
      width: 100%;
    }
  }
}

@media screen and (max-width: $tablet-min) {
  .item {
    margin: 0 0 $baseMargin * 4 0;

    .info {
      .title {
        margin: 0.25em 0 0 0;
        font-size: 3.5em;
      }

      .technologies {
        font-size: 1.6em;
      }
  
      .client {
        font-size: 2.5em;
      }
    }
  }
}