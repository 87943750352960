@import "../styles/variables";

.navigation {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%; // calc(100% - #{$baseMargin});

  .icons {
    display: flex;
    margin-left: auto;
    padding: 0.1em 0;

    .icon {
      margin: 0 12px 0 0;
      width: 0.8em;

      a:active {
        outline: none;
      }

      &:hover {
        svg {
          path {
            fill: $grey; // #0077B5;
          }
        }
      }

      svg {
        path {
          // fill: $grey;
          fill: white;
          transition: all 800ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }
      }
    }
  }
  
  .title {
    cursor: default;
    margin: 0;
    padding: 0;
  
    .details {
      padding: 0;
      margin: 0 0 $baseMargin 0;

      .icon {
        display: block; // none;
      }
  
      .name {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 100%;
        font-weight: 900;
        font-size: 5.7em; // 11.3em;
        // line-height: 0.8em;
        // font-size: 6.53em;
        color: white;
        letter-spacing: -0.06em;
        user-select: none;
        text-transform: uppercase;
        // z-index: -1;
        text-shadow: 3px 3px 6px rgba($grey, 0.4);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba($grey, 0.05);
        transition: color 15s cubic-bezier(0.190, 1.000, 0.220, 1.000);
        // background-color: red;

        // &:hover {
        //   color: $grey;
        // }
      }
  
      .role {
        display: flex;
        align-items: center;
        position: relative;
        margin: -0.5em 0 0 0;
        padding: 0 0 0 0.3em;
        color: white;
        font-size: 2.16em; // 4.8em;
        font-weight: 100;
        letter-spacing: -0.01em;
        line-height: 0.2em;

        .background {
          background-color: $pink;
        }
      }

      .divider {
        color: $grey; // $pink;
      }
    }
  }

  .contact {
    display: none; // flex;
    position: fixed;
    top: 0.7em;
    right: 20px;

    a {
      color: $grey;
      text-decoration: none;
      transition: color 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);

      &:hover {
        color: #0077B5;
      }
  
      svg {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media screen and (hover: hover) and (max-width: $tablet-max) {
    .linkedIn {
      &:hover {
        svg {
          path {
            fill: $grey;
          }
        }
      }
      &:focus {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-max) {
    .title {
      .details {
        .name {
          font-size: 6.53em;
        }
      }
    }

    // .contact {
    //   display: none;
    //   top: 15px;
    //   right: 10px;
    // }
  }

  @media screen and (max-width: $tablet-min) {
    .title {
      margin: 2em 0;

      .details {
        .name {
          font-size: 6.45em;
          text-align: left;
          color: $grey;
          text-shadow: 3px 3px 6px rgba($grey, 0);
          transition: color 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }
        .role {
          margin: -0.25em 0 0 0;
          font-size: 2.8em;
        }
      }
    }
  }
}
