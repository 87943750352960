// https://color.adobe.com/mythemes?viewTheme
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@100;500;900&display=swap);
@import "normalize.scss";
@import "styles/variables";

html,
body {
  position: relative;
  height: 100%;
  // texture_bg_light.sm.jpeg
}

body {
  padding: 0 1em;
  font-size: 1vw;
  font-family: 'Catamaran', sans-serif;
  font-weight: 100;
  background: rgb(255,255,255);
  // background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(226,226,226,1) 100%);

  p {
    margin: 0;
    padding: 0;
    line-height: 1em;
  }

  a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
    color: $pink;
    font-weight: 500;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  }
}

.App {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $baseMargin * 2;

  .texture {
    position: fixed;
    opacity: 0.6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background:white url('https://portfolio.mbtrueman.com/texture_bg_light.sm.jpeg') no-repeat 100% 0;
  }

  .skewed-background {
    position: absolute;
    // display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: skew(-20deg);
    background-color: $pink;
  }
}

.about {
  width: 100%; //50%;
  margin: $baseMargin 0 $baseMargin * 2 0;
  font-size: 16px;
  white-space: pre-wrap;
}

.workWrapper {
  // padding-top: 12em;
}

@media screen and (max-width: $tablet-max) {
  body {
    font-size: 1.5vw;
  }

  .about {
    width: 100%;
  }
}