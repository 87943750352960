$grey: #444; // 68,68,68
$pink: #F055DC;
$baseMargin: 2em;

$tablet-min: 780px;
$tablet-max: 980px;
$phone-min: 480px;
$max-width: 1500px;

:root {
  --swiper-theme-color: #F055DC;
}


:export {
  tabletMin: $tablet-min;
}